<template>
<div style="width:100%">
    <v-list-item class="pa-0">
        <v-row no-gutters>
          <v-col cols="12" md="1">
              <v-row no-gutters align="center">
                <v-col cols="12" md="2">
                  <v-card width="18" height="85" color="pink" @click="abrirColores"></v-card>
                </v-col>
                <v-col cols="12" md="10" class="text-center">
                  <v-row no-gutters>
                    <v-col cols="12" md="10" class="text-center">
                    <span style="font-size: 11px;">{{ item.codigo }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" md="10" class="text-center">
                    <span style="font-size: 9px;">{{ item.codigo_sfc }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <v-btn icon color="pink"><v-icon>mdi-heart</v-icon></v-btn> -->
          </v-col>
          <v-col cols="12" md="2">
              <v-list-item-content class="pa-0">
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.aplicativo.descripcion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.identificacion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.nombres }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescontacto.apellido1 + ' ' + item.gescontacto.apellido2 }}</span></v-list-item-title>
              </v-list-item-content>
          </v-col>
          <v-col cols="12" md="3">
              <v-list-item-content class="pa-0">
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ (item.canal===null)? item.ptorecepcion.descripcion : item.canal.descripcion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.retroalimentacion.descripcion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.ramo.descripcion }}</span></v-list-item-title>
                  <v-list-item-title><v-icon size="15">mdi-chevron-right</v-icon><span v-if="item.proceso !== null" style="font-size:10px">{{ item.proceso.descripcion }}</span></v-list-item-title>
              </v-list-item-content>
          </v-col>
          <v-col cols="12" md="3">
                <v-row no-gutters><v-col class="pl-2"><v-card :color="item.subestado.color" dark><center>{{ item.subestado.descripcion }}</center></v-card></v-col></v-row>
                <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CREADO: {{ rec_function_fecha_colombia(item.created_at) }}</span></v-col></v-row>
                <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px">{{ (item.usuario===null)?'':item.usuario.nombre_usuario + ' ' + item.usuario.apellido_usuario }}</span></v-col></v-row>
                <v-row v-if="item.subestado_id !== 4" no-gutters><v-col class="pr-2"><v-card class="pl-2" :color="colorVencimiento(item)" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ item.fechaparacerrar }}</span> <span v-if="item.dias>=1" class="pl-1" style="font-size:10px">VENCIDO {{ item.dias }} {{ (item.dias === 1)? ' DIA' : 'DIAS' }}</span></v-card></v-col></v-row>
                <v-row v-if="item.subestado_id === 4" no-gutters><v-col class="pr-2"><v-card class="pl-2" :color="colorVencimiento_cerrado(item)" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRADO EL {{ item.fechacerrado }}</span> <span v-if="Date.parse(item.fechacerrado) > Date.parse(item.fechaparacerrar)" class="pl-1" style="font-size:10px">VENCIDO {{ item.dias }} {{ (item.dias === 1)? ' DIA' : 'DIAS' }}</span></v-card></v-col></v-row>
          </v-col>
          <v-col cols="12" md="3" align-self="center">
              <v-row no-gutters align="center">
                <v-col cols="12" md="10">
                    <v-row v-for="(itemesc, index) in item.escalamientos" no-gutters class="pt-1" :key="index">
                      <v-col>
                      <v-chip small outlined color="green">
                        <v-avatar left><v-icon size="15">mdi-check-bold</v-icon></v-avatar>
                        <span style="font-size:10px">{{ itemesc.area.descripcion }}</span>
                      </v-chip>
                      </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="2" align-self="center" class="text-center">
                  <v-btn @click="ver(item)" icon color="blue"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-list-item>
      <v-divider></v-divider>
 </div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'listadoComponent',
  components: {

  },
  props: ['item'],
  mixins: [recFunciones],
  data: () => ({
    dialogLectura: false,
    token: 0,
    registro_id: 0
  }),
  created () {
  },
  methods: {
    colorVencimiento (item) {
      let color = 'black'
      if (item.dias < -5) {
        color = 'green'
      } else if (item.dias >= -5 && item.dias <= -1) {
        color = 'amber'
      } else {
        color = 'red'
      }
      return color
    },
    colorVencimiento_cerrado (item) {
      let color = 'black'
      if (Date.parse(item.fechacerrado) < Date.parse(item.fechaparacerrar)) {
        color = 'green'
      } else {
        color = 'red'
      }
      return color
    },
    abrirColores () {

    },
    ver (pItem) {
      this.$emit('select_pqr', pItem)
    }

  }
}
</script>
